import React from "react";
import { Typography, Avatar, Stack } from "@mui/material";
import styled from "@emotion/styled"

const HeaderLogo = (props: any) => (
    <CustomStack direction="row" color="inherit" {...props}>
        <Avatar
            variant="square"
            sx={{ backgroundColor: "transparent" }}
            src={require("assets/logo.webp")}
            alt="StockEdge Social Logo"
        />
        <Typography
            variant="h1"
            noWrap
        >StockEdge Social</Typography>
    </CustomStack>
)

export default HeaderLogo;



const CustomStack = styled(Stack)`
    && {
        text-decoration: none;
        align-items: center;
        .MuiTypography-h1 {
            margin-left: 12px;
        }
        @media (max-width: 480px) {
            .MuiTypography-h1 {
                margin-left: 4px;
                font-size: 1.125rem;
                font-weight: 500;
            }
        }
        @media (max-width: 375px) {
            .MuiTypography-h1 {
                font-size: 1rem;
            }
        }
        @media (max-width: 540px) {
            .MuiTypography-h1 {
                display: none;
            }
        }
    }
`;
