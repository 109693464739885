import { ApolloCache, ApolloProvider } from "@apollo/client";
import { FC, PropsWithChildren } from "react";
import {
  DollarFollowMutation,
  DollarTag,
  DollarTagFieldsFragmentDoc,
  Hashtag,
  HashTagFieldsFragmentDoc,
  Maybe,
  UpdateHashtagFragmentDoc,
  DollarTagEnum,
  User,
  FollowMutation,
  UpdateUserFragmentDoc,
  UnfollowMutation,
  CancelFollowMutation,
  SubscribeMutation,
  UnsubscribeMutation,
  Club,
  UpdateClubFragmentDoc,
  UserFieldsFragmentDoc,
  JoinClubMutation,
  LeaveClubMutation,
  SubscribeClubMutation,
  UnsubscribeClubMutation,
} from "api";

import { useClient } from "./client";

const Provider: FC<PropsWithChildren> = ({ children }) => {
  const client = useClient();
  return <ApolloProvider client={client}>{children}</ApolloProvider>;
};

export default Provider;

// Methods for Inmemory Updation
export const updateCacheOnFollowHashTag = (
  cache: ApolloCache<any>,
  hashtag: Maybe<Hashtag> | undefined
) => {
  if (hashtag) {
    cache.modify({
      fields: {
        getHashtagFollowings(existingHashtags, { storeFieldName }) {
          const newHashTagRef = cache.writeFragment({
            data: Object.assign({}, hashtag, {
              isfollow: 1,
              issubscribed: 0,
              followers: hashtag?.followers ?? 0 + 1,
            }),
            fragment: HashTagFieldsFragmentDoc,
          });
          return [newHashTagRef, ...existingHashtags];
        },
      },
    });
    cache.updateFragment(
      {
        id: cache.identify(hashtag),
        fragment: UpdateHashtagFragmentDoc,
      },
      (d) => ({
        ...d,
        isfollow: 1,
        issubscribed: 0,
        followers: d.followers + 1,
      })
    );
  }
};

export const updateCacheOnUnFollowHashTag = (
  cache: ApolloCache<any>,
  hashtag: Maybe<Hashtag> | undefined
) => {
  if (hashtag) {
    cache.modify({
      fields: {
        getHashtagFollowings: (existingHashtags, { readField }) => {
          return existingHashtags.filter(
            (hashTagRef: any) =>
              hashtag.hashtagName !== readField("hashtagName", hashTagRef)
          );
        },
      },
    });
    cache.updateFragment(
      {
        id: cache.identify(hashtag),
        fragment: UpdateHashtagFragmentDoc,
      },
      (d) => ({
        ...d,
        isfollow: 0,
        issubscribed: 0,
        followers: d.followers - 1,
      })
    );
  }
};

export const updateCacheOnSubscribeHashTag = (
  cache: ApolloCache<any>,
  hashtag: Maybe<Hashtag> | undefined
) => {
  if (hashtag) {
    cache.updateFragment(
      {
        id: cache.identify(hashtag),
        fragment: UpdateHashtagFragmentDoc,
      },
      (d) => ({
        ...d,
        issubscribed: 1,
      })
    );
  }
};

export const updateCacheOnUnsubscribeHashTag = (
  cache: ApolloCache<any>,
  hashtag: Maybe<Hashtag> | undefined
) => {
  if (hashtag) {
    cache.updateFragment(
      {
        id: cache.identify(hashtag),
        fragment: UpdateHashtagFragmentDoc,
      },
      (d) => ({
        ...d,
        issubscribed: 0,
      })
    );
  }
};

export const updateCacheOnFollowDollarTag = (
  cache: ApolloCache<any>,
  dollar: Maybe<DollarTag> | undefined,
  data: DollarFollowMutation | null | undefined
) => {
  if (dollar) {
    cache.modify({
      fields: {
        getWatchlistV2: (existingTodos, { storeFieldName }) => {
          const newTodoRef = cache.writeFragment({
            data: Object.assign({}, dollar, data?.dollarFollow),
            fragment: DollarTagFieldsFragmentDoc,
          });
          if (
            dollar.dollarTagType === DollarTagEnum.Stock &&
            storeFieldName === 'getWatchlistV2:{"type":"Stock"}'
          ) {
            return [newTodoRef, ...existingTodos];
          }
          if (
            [DollarTagEnum.Sector, DollarTagEnum.Industry].includes(
              dollar.dollarTagType
            ) &&
            storeFieldName === 'getWatchlistV2:{"type":"Sector"}'
          ) {
            return [newTodoRef, ...existingTodos];
          }
          return existingTodos;
        },
      },
    });
  }
};

export const updateCacheOnUnFollowDollarTag = (
  cache: ApolloCache<any>,
  dollar: Maybe<DollarTag> | undefined,
  data: DollarFollowMutation | null | undefined
) => {
  if (dollar) {
    cache.modify({
      fields: {
        getWatchlistV2: (existingTodos, { storeFieldName, readField }) => {
          if (storeFieldName === 'getWatchlistV2:{"type":"Stock"}') {
            return existingTodos.filter(
              (dollarTagRef: any) =>
                dollar?.dollarTagId !== readField("dollarTagId", dollarTagRef)
            );
          }
          if (storeFieldName === 'getWatchlistV2:{"type":"Sector"}') {
            return existingTodos.filter(
              (dollarTagRef: any) =>
                dollar?.dollarTagId !== readField("dollarTagId", dollarTagRef)
            );
          }
          return existingTodos;
        },
      },
    });
  }
};

export const updateCacheOnFollowUser = (
  cache: ApolloCache<any>,
  user: Maybe<User> | undefined,
  data: FollowMutation | null | undefined
) => {
  if (user) {
    cache.modify({
      fields: {
        getSuggestedPeople(existingUsers, { readField }) {
          return existingUsers.filter(
            (userRef: any) => user.id !== readField("id", userRef)
          );
        },
        getFollowersCount(existing, { storeFieldName }) {
          if (
            storeFieldName ===
              `getFollowersCount({"data":{"entity":"User","id":"${user.id}"}})` &&
            data?.follow?.followStatus === "Resolved"
          ) {
            return existing + 1;
          }
          return existing;
        },
      },
    });
    cache.updateFragment(
      {
        id: cache.identify(user),
        fragment: UpdateUserFragmentDoc,
      },
      (d) => ({
        ...d,
        isfollow: data?.follow?.followStatus,
      })
    );
  }
};

export const updateCacheOnUnfollowUser = (
  cache: ApolloCache<any>,
  user: Maybe<User> | undefined,
  data: UnfollowMutation | null | undefined
) => {
  if (user) {
    cache.modify({
      fields: {
        getFollowersCount(existing, { storeFieldName }) {
          if (
            storeFieldName ===
            `getFollowersCount({"data":{"entity":"User","id":"${user.id}"}})`
          ) {
            return existing - 1;
          }
          return existing;
        },
      },
    });
    cache.updateFragment(
      {
        id: cache.identify(user),
        fragment: UpdateUserFragmentDoc,
      },
      (d) => ({
        ...d,
        isfollow: "False",
        issubscribed: 0,
      })
    );
  }
};

export const updateCacheOnCancelFollowUser = (
  cache: ApolloCache<any>,
  user: Maybe<User> | undefined,
  data: CancelFollowMutation | null | undefined
) => {
  if (user) {
    cache.updateFragment(
      {
        id: cache.identify(user),
        fragment: UpdateUserFragmentDoc,
      },
      (d) => ({
        ...d,
        isfollow: "False",
        issubscribed: 0,
      })
    );
  }
};

export const updateCacheOnSubscribeUser = (
  cache: ApolloCache<any>,
  user: Maybe<User> | undefined,
  data: SubscribeMutation | null | undefined
) => {
  if (user) {
    console.log("updateCacheOnSubscribeUser");
    cache.updateFragment(
      {
        id: cache.identify(user),
        fragment: UpdateUserFragmentDoc,
      },
      (d) => ({
        ...d,
        issubscribed: 1,
      })
    );
  }
};

export const updateCacheOnUnsubscripeUser = (
  cache: ApolloCache<any>,
  user: Maybe<User> | undefined,
  data: UnsubscribeMutation | null | undefined
) => {
  if (user) {
    console.log("updateCacheOnUnsubscripeUser");
    cache.updateFragment(
      {
        id: cache.identify(user),
        fragment: UpdateUserFragmentDoc,
      },
      (d) => ({
        ...d,
        issubscribed: 0,
      })
    );
  }
};

export const updateCacheOnJoinClub = (
  cache: ApolloCache<any>,
  user: Maybe<User> | undefined,
  club: Maybe<Club> | undefined,
  data: JoinClubMutation | null | undefined
) => {
  if (club) {
    // Add user to club member
    cache.modify({
      fields: {
        getMembers(existingMemberRefs, { storeFieldName }) {
          console.log({ storeFieldName });
          if (storeFieldName === `getMembers:{"clubId":"${club?.clubId}"}`) {
            const newMemberRef = cache.writeFragment({
              data: Object.assign({}, user, {
                __typename: "User",
              }),
              fragment: UserFieldsFragmentDoc,
            });
            return [newMemberRef, ...existingMemberRefs];
          }
          return existingMemberRefs;
        },
      },
    });
    cache.modify({
      id: cache.identify({
        clubId: club.clubId,
        __typename: "Club",
      }),
      fields: {
        ismember: () => "Resolved",
        issubscribed: () => 0,
        totalMemberCount: (old: number) => old + 1,
      },
    });
  }
};

export const updateCacheOnLeaveClub = (
  cache: ApolloCache<any>,
  user: Maybe<User> | undefined,
  club: Maybe<Club> | undefined,
  data: LeaveClubMutation | null | undefined
) => {
  if (club) {
    // Add user to club member
    cache.modify({
      fields: {
        getMembers: (existingMemberRefs, { storeFieldName, readField }) => {
          console.log({ storeFieldName });
          if (storeFieldName === `getMembers:{"clubId":"${club?.clubId}"}`) {
            return existingMemberRefs.map(
              (memberRef: any) => user?.id !== readField("id", memberRef)
            );
          }
          return existingMemberRefs;
        },
      },
    });
    cache.modify({
      id: cache.identify({
        clubId: club.clubId,
        __typename: "Club",
      }),
      fields: {
        ismember: () => "False",
        issubscribed: () => 0,
        totalMemberCount: (old: number) => old - 1,
      },
    });
  }
};

export const updateCacheOnSubscribeClub = (
  cache: ApolloCache<any>,
  club: Maybe<Club> | undefined,
  data: SubscribeClubMutation | null | undefined
) => {
  if (club) {
    cache.updateFragment(
      {
        id: cache.identify(club),
        fragment: UpdateClubFragmentDoc,
      },
      (d) => ({
        ...d,
        issubscribed: 1,
      })
    );
  }
};

export const updateCacheOnUnsubscribeClub = (
  cache: ApolloCache<any>,
  club: Maybe<Club> | undefined,
  data: UnsubscribeClubMutation | null | undefined
) => {
  if (club) {
    cache.updateFragment(
      {
        id: cache.identify(club),
        fragment: UpdateClubFragmentDoc,
      },
      (d) => ({
        ...d,
        issubscribed: 0,
      })
    );
  }
};
