import React, { FC, ReactNode, useEffect, useState } from "react";

import {
  Divider,
  IconButton,
  Select,
  SelectChangeEvent,
  Stack,
  styled,
  Typography,
} from "@mui/material";
import { BackIcon } from "icons";
import { Link, useNavigate } from "react-router-dom";
import {
  GetClubDocument,
  GetClubQuery,
  GetClubQueryVariables,
  GetDollarTagDetailsDocument,
  GetDollarTagDetailsQuery,
  GetDollarTagDetailsQueryVariables,
  GetHashTagDetailsDocument,
  GetHashTagDetailsQuery,
  GetHashTagDetailsQueryVariables,
  PostCategoryForSearch,
  ProfileInfoDocument,
  ProfileInfoQuery,
  ProfileInfoQueryVariables,
} from "api";
import { v4 } from "uuid";
import { useWindowWidth } from "@react-hook/window-size";
import { ApolloQueryResult, useApolloClient } from "@apollo/client";

interface FilterPostProps {
  hashTagId: string | undefined;
  userId: string | undefined;
  dollarId: number | undefined;
  clubId: string | undefined;
  postCategory: string | undefined;
  handleChange: (event: SelectChangeEvent<string | null>) => void;
}

const FilterContainer: FC<FilterPostProps> = ({
  hashTagId,
  userId,
  dollarId,
  clubId,
  postCategory,
  handleChange,
}) => {
  const client = useApolloClient();
  const navigate = useNavigate();
  const [renderArray, setRenderArray] = useState<Array<ReactNode>>([]);

  useEffect(() => {
    const requests: Array<Promise<ApolloQueryResult<any>>> = [];
    if (userId) {
      requests.push(
        client.query<ProfileInfoQuery, ProfileInfoQueryVariables>({
          query: ProfileInfoDocument,
          variables: { data: { userId } },
        })
      );
    }
    if (hashTagId) {
      requests.push(
        client.query<GetHashTagDetailsQuery, GetHashTagDetailsQueryVariables>({
          query: GetHashTagDetailsDocument,
          variables: { hashtagId: hashTagId },
        })
      );
    }
    if (dollarId) {
      requests.push(
        client.query<
          GetDollarTagDetailsQuery,
          GetDollarTagDetailsQueryVariables
        >({
          query: GetDollarTagDetailsDocument,
          variables: { dollarTagId: Number(dollarId) },
        })
      );
    }
    if (clubId) {
      requests.push(
        client.query<GetClubQuery, GetClubQueryVariables>({
          query: GetClubDocument,
          variables: { clubId },
        })
      );
    }

    Promise.all(requests).then((values) => {
      const data = values.reduce((prev: any, { data }) => {
        if (data?.getClub)
          return {
            ...prev,
            getClub: data?.getClub,
          };
        if (data?.getProfileInfo)
          return {
            ...prev,
            getProfileInfo: data?.getProfileInfo,
          };
        if (data?.getDollarTagDetails)
          return {
            ...prev,
            getDollarTagDetails: data?.getDollarTagDetails,
          };
        if (data?.getHashTagDetails)
          return {
            ...prev,
            getHashTagDetails: data?.getHashTagDetails,
          };
      }, {});
      const renderArray: Array<ReactNode> = [];
      if (data?.getDollarTagDetails || data?.getHashTagDetails) {
        renderArray.push(<span> for</span>);
      }
      if (data?.getDollarTagDetails) {
        renderArray.push(
          <MentionStockTagLink
            key={v4()}
            // target="_blank"
            to={`/${data.getDollarTagDetails.dollarTagType}/${data.getDollarTagDetails.slug}/${data.getDollarTagDetails.dollarTagId}`}
            replace={window.location.pathname.includes(
              `/${data.getDollarTagDetails.dollarTagType}/${data.getDollarTagDetails.slug}/${data.getDollarTagDetails.dollarTagId}`
            )}
          >
            {` $${data.getDollarTagDetails.dollarTagName}`}
          </MentionStockTagLink>
        );
      }
      if (data?.getHashTagDetails) {
        renderArray.push(
          <MentionHashTagLink
            key={v4()}
            // target="_blank"
            to={`/hashtag/${encodeURIComponent(data?.getHashTagDetails.hashtag?.hashtagName)}`}
            replace={window.location.pathname.includes(
              `/hashtag/${data?.getHashTagDetails.hashtag?.hashtagName}`
            )}
          >
            {` #${data?.getHashTagDetails.hashtag?.hashtagName}`}
          </MentionHashTagLink>
        );
      }
      if (data?.getProfileInfo) {
        renderArray.push(
          <>
            <span> by </span>
            <MentionUserLink
              key={v4()}
              // target="_blank"
              to={`/profile/${encodeURIComponent(
                data?.getProfileInfo?.username
              )}`}
              replace={window.location.pathname.includes(
                `/profile/${encodeURIComponent(data?.getProfileInfo?.username)}`
              )}
            >
              {` @${data?.getProfileInfo?.firstName} ${data?.getProfileInfo?.lastName}`}
            </MentionUserLink>
          </>
        );
      }
      if (data?.getClub) {
        renderArray.push(
          <>
            <span> in </span>
            <MentionUserLink
              key={v4()}
              // target="_blank"
              to={`/club/${data.getClub.clubId}`}
              replace={window.location.pathname.includes(
                `/club/${data.getClub.clubId}`
              )}
            >
              {` ${data.getClub.clubName}`}
            </MentionUserLink>
          </>
        );
      }
      setRenderArray(renderArray);
    });
  }, [hashTagId, userId, dollarId, clubId, setRenderArray]);

  const width = useWindowWidth();
  return (
    <Container>
      {width >= 540 ? (
        <ButtonContainer onClick={() => navigate(-1)}>
          <IconButton edge="start" color="inherit" className="Only-Mobile">
            <BackIcon />
          </IconButton>
          <Typography sx={{ cursor: "pointer" }}>Back</Typography>
        </ButtonContainer>
      ) : null}
      <TextContainer>
        <Typography pt={2}>
          Search results
          {renderArray}
        </Typography>
        <PostCategoryContainer>
          <Divider sx={{ flex: 1 }} />
          <Typography fontSize={".688rem"} padding={"0px 4px"}>
            Post Category
          </Typography>
          <Select
            native
            value={postCategory}
            onChange={handleChange}
            size="small"
            sx={{
              width: "95px",
              fontSize: "0.688rem",
              ".MuiNativeSelect-select": {
                padding: "4px 8px !important",
              },
              svg: {
                right: "0px",
              },
            }}
          >
            <option
              key={PostCategoryForSearch.All}
              value={PostCategoryForSearch.All}
            >
              All
            </option>
            <option
              key={PostCategoryForSearch.Query}
              value={PostCategoryForSearch.Query}
            >
              Query
            </option>
            <option
              key={PostCategoryForSearch.StockIdea}
              value={PostCategoryForSearch.StockIdea}
            >
              Stock Idea
            </option>
          </Select>
        </PostCategoryContainer>
      </TextContainer>
    </Container>
  );
};

export default FilterContainer;

const Container = styled("div")`
  max-width: 540px;
  margin: auto;
`;

const ButtonContainer = styled("div")`
  width: 60px;
  display: flex;
  align-items: center;
  padding-top: 5px;
`;

const TextContainer = styled("div")`
  padding: 5px;
  padding-inline: 8px;
`;

const MentionUserLink = styled(Link)`
  text-decoration: none;
  color: rgb(27, 149, 224);
  font-weight: 600;
  &:hover {
    text-decoration: underline;
  }
`;

const MentionHashTagLink = styled(Link)`
  text-decoration: none;
  color: #1b6ac9;
  font-weight: 600;
  &:hover {
    text-decoration: underline;
  }
`;

const MentionStockTagLink = styled(Link)`
  text-decoration: none;
  color: rgb(58 105 186);
  font-weight: 600;
  &:hover {
    cursor: pointer;
    text-decoration: underline;
  }
`;

const PostCategoryContainer = styled(Stack)(({ theme }) => {
  return {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    padding: "8px 0",
    marginTop: "8px",
    marginBottom: "-8px",
    position: "sticky",
    top: "56px",
    zIndex: 999,
    backgroundColor: theme.palette.background.default,
  };
});
