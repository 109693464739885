import { Box, useMediaQuery } from "@mui/material";
import { detect } from "detect-browser";
import React, { FC, lazy, PropsWithChildren, Suspense, useState } from "react";
import { withAuthenticationRequired } from "react-oidc-context";
import styled from "@emotion/styled";

import { ProductTourProvider } from "providers/ProductTour";
import { Outlet } from "react-router-dom";
import NPS from "context/nps";
import { BadgeProvider, BadgeHandle } from "context/badge/BadgeContext";
import { SourceVisitorCode } from "components/SourceVisitorCode";

import CreateAndSharePostProvider from "features/create-and-share-post";

const Header = lazy(() => import("./components/Header"));

const PushNotification = lazy(() => import("./PushNotification"));

const BottomNavigation = lazy(
  () => import("layouts/MainLayout/components/ButtomNavigation")
);

const FloatingButtonMenu = lazy(() =>
  import("features/create-and-share-post").then(({ FloatingButtonMenu }) => ({
    default: FloatingButtonMenu,
  }))
);

const LeftPanel = lazy(() => import("./LeftPanel"));
const RightPanel = lazy(() => import("./RightPanel"));

export const badgeRef = React.createRef<BadgeHandle>();

const isAllowPushNotifications = (): boolean => {
  const browser = detect();
  if (browser?.name === "ios") return false;
  if (browser?.name === "safari") return false;
  if (browser?.name === "crios") return false;
  if (browser?.name === "chromium-webview") return false;
  return true;
};

const MainLayout: FC<PropsWithChildren<any>> = ({ children }) => {
  const isVisible = useMediaQuery("(min-width:960px)");
  const [openPrompt, setOpenPrompt] = useState(false);

  return (
    <NPS>
      <SourceVisitorCode />
      <ProductTourProvider>
        <CreateAndSharePostProvider>
          <BadgeProvider ref={badgeRef}>
            <Suspense>
              <Header
                openPrompt={openPrompt as any}
                setOpenPrompt={setOpenPrompt}
              />
            </Suspense>
            <Main>
              {isVisible && (
                <Suspense>
                  <LeftPanel />
                </Suspense>
              )}
              <div>
                <Outlet />
                <Box height={80} />
              </div>
              {isVisible && (
                <Suspense>
                  <RightPanel />
                </Suspense>
              )}
            </Main>
            {isAllowPushNotifications() && (
              <Suspense>
                <PushNotification />
              </Suspense>
            )}
            <Suspense>
              <FloatingButtonMenu />
            </Suspense>
            <Suspense>
              <BottomNavigation />
            </Suspense>
          </BadgeProvider>
        </CreateAndSharePostProvider>
      </ProductTourProvider>
    </NPS>
  );
};

export default withAuthenticationRequired(MainLayout, {
  OnRedirecting: () => <div>Redirecting to the login page...</div>,
});

const Main = styled.main`
  display: grid;
  grid-template-columns: 1fr;
  @media only screen and (min-width: 960px) {
    grid-column-gap: 10px;
    grid-template-columns: 280px 1fr 280px;
  }
  @media only screen and (min-width: 1140px) {
    grid-template-columns: 320px 1fr 320px;
  }
  @media only screen and (min-width: 1281px) {
    grid-template-columns: 360px 1fr 360px;
  }
`;
