import { PaletteMode } from "@mui/material";
import { createTheme } from "@mui/material/styles";

declare module "@mui/material/styles" {
  interface Theme {
    background: {
      default: string;
      transparent: string;
      mention: string;
    };
    mention: {
      background: string;
      border: string;
      focus: string;
    };
    poll: {
      background: string;
    };
    selectedpoll: {
      background: string;
    };
  }
  // allow configuration using `createTheme`
  interface ThemeOptions {
    background?: {
      transparent?: string;
      mention?: string;
    };
    mention?: {
      background?: string;
      border?: string;
      focus?: string;
    };
    poll?: {
      background?: string;
    };
    selectedpoll?: {
      background?: string;
    };
  }
}
export interface Colors {
  background: {
    default: string;
    card: string;
    header: string;
    disableButton: string;
    transparent: string;
    mention: string;
    selectedpoll:string
  };
  color: {
    default: string;
    title: string;
    subtitle: string;
  };
}

const theme = (mode: PaletteMode, colors: Colors | any) =>
  createTheme({
    shape: {
      borderRadius: 6,
    },
    palette: {
        mode,
        background: {
            default: colors?.background?.default,
            paper: colors?.background?.card
        },
        primary: {
            main: "#1b6ac9",
            light: "#1b6ac9",
            dark: "#1b6ac9"
        },
        secondary: {
            main: "#7170701f",
            light: "#7170701f",
            dark: "#7170701f"
        },
    },
    typography: {
      allVariants: {
        color: colors?.color?.default,
      },
      fontFamily: ["Trebuchet MS", "Open Sans", "sans-serif"].join(","),
      h1: {
        fontFamily: "Open Sans",
        fontSize: "1.5rem",
        fontWeight: 400,
      },
      h2: {
        fontSize: "1.25rem",
        fontWeight: "bold",
      },
      subtitle2: {
        lineHeight: "1rem",
        whiteSpace: "pre-wrap",
        overflowWrap: "break-word",
        wordWrap: "break-word",
        wordBreak: "break-word",
      },
      caption: {
        color: "rgb(92, 112, 130)",
        fontSize: "0.875rem",
        lineHeight: 1.3333,
        fontWeight: 400,
      },
      h3: {
        color: colors.color.title,
        fontSize: "1.875rem",
        fontWeight: "bold",
      },
      h4: {
        fontSize: "1.5rem",
      },
      h5: {
        color: colors.color.subtitle,
        fontSize: "1.125rem",
        fontWeight: "bold",
      },
      h6: {
        fontSize: "1rem",
        fontWeight: "bold",
        whiteSpace: "pre-wrap",
        overflowWrap: "break-word",
        wordWrap: "break-word",
        wordBreak: "break-word",
      },
      button: {
        textTransform: "initial",
      },
      body2: {
        fontSize: "0.813rem",
        whiteSpace: "pre-wrap",
        overflowWrap: "break-word",
        wordWrap: "break-word",
        wordBreak: "break-word",
        // color: "#5c7082"
      },
    },
    mixins: {
      toolbar: {
        minHeight: 56,
        "@media (min-width:600px)": {
          minHeight: 56,
        },
      },
    },
    components: {
      MuiAppBar: {
        styleOverrides: {
          colorPrimary: {
            backgroundColor: colors.background.header,
            color: colors?.color?.default,
          },
        },
      },
      MuiTypography: {
        styleOverrides: {
          button: {
            textTransform: "initial",
          },
          h4: {
            fontSize: "1.5rem",
          },
        },
      },
      MuiAvatar: {
        styleOverrides: {
          root: {
            backgroundColor: "#fafafa",
            color: "#000",
          },
        },
      },
      MuiButton: {
        styleOverrides: {
          // containedPrimary: {
        //     backgroundColor: "#2196f3",
        //     color: "#ffffff",
        //     fontWeight: "bold",
        //     "&:hover": {
      //         backgroundColor: "#2196f3",
        //     },
          // },
          containedSecondary: {
            color: colors?.color.default,
            fontWeight: "bold",
          },
          textPrimary: {
            color: colors?.color.default,
          },
          root: {
            "&.Mui-maxWidth": {
              maxWidth: 120,
            },
            "&.Mui-disabled": {
              backgroundColor: "#7170701f",
            },
          },
        },
      },
      MuiListItem: {
        styleOverrides: {
          root: {
            color: colors.color.default,
          },
        },
      },
      MuiListItemButton: {
        styleOverrides: {
          root: {
            borderRadius: 6,
          },
        },
      },
      MuiTableCell: {
        defaultProps: {
          align: "left",
        },
        styleOverrides: {
          root: {
            padding: 12,
          },
        },
      },
      MuiCard: {
        defaultProps: {
          variant: "outlined",
        },
      },
      MuiBottomNavigation: {
        styleOverrides: {
          root: {
            height: 48,
          },
        },
      },
      MuiBottomNavigationAction: {
        styleOverrides: {
          root: {
            minWidth: 60,
          },
        },
      },
      MuiPaper: {
        defaultProps: {
          variant: "outlined",
          elevation: 0,
        },
      },
      MuiTab: {
        styleOverrides: {
          root: {
            padding: "8px 4px",
            minWidth: 50,
            "@media (min-width: 360px)": {
              minWidth: 56,
            },
            "@media (min-width: 375px)": {
              minWidth: 60,
            },
            "@media (min-width: 480px)": {
              minWidth: 72,
            },
            "@media (min-width: 540px)": {
              minWidth: 90,
            },
          },
        },
      },
      MuiButtonGroup: {
        styleOverrides: {
          grouped: {
            fontSize: "0.725rem",
            lineHeight: "1rem",
            padding: 4,
          },
        },
      },
      MuiCardContent: {
        styleOverrides: {
          root: {
            paddingTop: 0,
            paddingBottom: 0,
            "&:last-child": {
              paddingBottom: 0,
            },
          },
        },
      },
      MuiDivider: {
        styleOverrides: {
          vertical: {
            borderColor: colors.color.title,
          },
        },
      },
      MuiSelect: {
        styleOverrides: {
          select: {
            fontSize: "0.875rem",
          },
        },
      },
      MuiMenuItem: {
        defaultProps: {
          dense: true,
        },
      },
    },
    // Custom
    background: {
      transparent: colors.background.transparent,
      mention: colors.background.mention,
    },
    mention: {
      background: colors.mention.background,
      border: colors.mention.border,
      focus: colors.mention.focusColor,
    },
    poll: {
      background: colors.poll.background,
    },
    selectedpoll: {
      background:colors.background.selectedpoll,
    },
  });

export default theme;
