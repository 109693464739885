import {
  Button,
  Dialog,
  DialogContent,
  IconButton,
  Paper,
  Stack,
} from "@mui/material";
import React, { FC, ReactNode, useCallback } from "react";
import styled from "@emotion/styled";
import Avatar from "components/Avatar";
import {
  useMeQuery,
  useGetFeatureToPlatformPermissionQuery,
  PlatformPermissionFromFeatureTypes,
  ClubPermissionsBasedOnMemberType,
  PermissionsInAnyClub,
  useGetPostPermissionsInAnyClubQuery,
} from "api";
import { DEFAULT_AVATAR } from "config";
import { getIsRingVisible } from "utills";
import { ActionTypes, useCreateAndSharePostContext } from ".";
import { useLocation } from "react-router-dom";
import { useClubPermission } from "hooks";

import { Close } from "@mui/icons-material";

import ShareAnInsightIcon from "assets/icons/svg/share-an-insight.svg";
import AskAQueryIcon from "assets/icons/svg/ask-a-query.svg";
import ShareAStockIdeaIcon from "assets/icons/svg/share-a-stock-idea.svg";
import { isEmpty } from "lodash-es";

const extractClubId = (path) => {
  const match = path.match(/\/club\/([a-zA-Z0-9-]+)(?:\/.*|$)/);
  return match ? match[1] : null;
};

export const CreatePostButton: FC = () => {
  const [open, setOpen] = React.useState(false);
  const [, dispatch] = useCreateAndSharePostContext();
  const { data: me } = useMeQuery();
  const { pathname } = useLocation();
  const clubId = extractClubId(pathname);
  const { permissions } = useClubPermission(clubId);
  const { data } = useGetFeatureToPlatformPermissionQuery();
  const { data: postPermissionsInAnyClub } =
    useGetPostPermissionsInAnyClubQuery();
  const menus: Array<ReactNode> = [];
  const handleOnClick = () => {
    !isEmpty(menus) && setOpen(true);
  };

  const handleCloseModal = () => {
    setOpen(false);
  };

  const handleOpenQueryModal = useCallback(() => {
    setOpen(false);
    dispatch?.({
      type: ActionTypes.OPEN_QUERY_MODAL,
      clubId,
      isAudienceChangeAble: !clubId,
    });
  }, [clubId, dispatch, setOpen]);

  const handleOpenPostModal = useCallback(() => {
    setOpen(false);
    dispatch?.({
      type: ActionTypes.OPEN_POST_MODAL,
      clubId,
      isAudienceChangeAble: !clubId,
    });
  }, [clubId, dispatch, setOpen]);

  const handleOpenStockIdeaModal = useCallback(() => {
    setOpen(false);
    dispatch?.({
      type: ActionTypes.OPEN_STOCK_IDEA_MODAL,
      clubId,
      isAudienceChangeAble: !clubId,
    });
  }, [clubId, dispatch, setOpen]);

  const ShareAnInsightButton = () => (
    <Button
      variant="outlined"
      startIcon={<ShareAnInsightIcon />}
      sx={{
        "&&": {
          justifyContent: "flex-start",
          fontWeight: "bold",
          padding: "10px 24px",
        },
      }}
      size="large"
      disableElevation
      onClick={handleOpenPostModal}
    >
      Start a Discussion
    </Button>
  );
  const AskAQueryButton = () => (
    <Button
      variant="contained"
      startIcon={<AskAQueryIcon />}
      sx={{
        "&&": {
          justifyContent: "flex-start",
          fontWeight: "bold",
          padding: "10px 24px",
        },
      }}
      size="large"
      disableElevation
      onClick={handleOpenQueryModal}
    >
      Ask a Query
    </Button>
  );
  const ShareAStockIdeaButton = () => (
    <Button
      variant="contained"
      startIcon={<ShareAStockIdeaIcon />}
      sx={{
        "&&": {
          justifyContent: "flex-start",
          fontWeight: "bold",
          padding: "10px 24px",
        },
      }}
      size="large"
      disableElevation
      onClick={handleOpenStockIdeaModal}
    >
      Share a Stock Idea
    </Button>
  );

  const uiButton: Array<ReactNode> = [];
  // PERMISSIONS CHECK
  if (clubId) {
   
    // Ask a Query
    if (permissions?.includes(ClubPermissionsBasedOnMemberType.CanQuery)) {
      menus.push(<AskAQueryButton />);
      uiButton.push(
        <CustomPaper variant="outlined">
          <Avatar
            src={me?.me?.avatar?.LowResUri || DEFAULT_AVATAR}
            alt="Avatar"
            hasRing={getIsRingVisible(me?.me?.membershipLevel)}
          />
          <Button fullWidth onClick={handleOpenQueryModal}>
            Ask a Query
          </Button>
        </CustomPaper>
      );
    }
    // Share a Stock Idea
    if (
      permissions?.includes(ClubPermissionsBasedOnMemberType.CanCreateStockIdea)
    ) {
      menus.push(<ShareAStockIdeaButton />);
      uiButton.push(
        <CustomPaper variant="outlined">
          <Avatar
            src={me?.me?.avatar?.LowResUri || DEFAULT_AVATAR}
            alt="Avatar"
            hasRing={getIsRingVisible(me?.me?.membershipLevel)}
          />
          <Button fullWidth onClick={handleOpenStockIdeaModal}>
            Share a Stock Idea
          </Button>
        </CustomPaper>
      );
    }
     // Share an Insight
     if (permissions?.includes(ClubPermissionsBasedOnMemberType.CanPost)) {
      menus.push(<ShareAnInsightButton />);
      uiButton.push(
        <CustomPaper variant="outlined">
          <Avatar
            src={me?.me?.avatar?.LowResUri || DEFAULT_AVATAR}
            alt="Avatar"
            hasRing={getIsRingVisible(me?.me?.membershipLevel)}
          />
          <Button fullWidth onClick={handleOpenPostModal}>
            Start a Discussion
          </Button>
        </CustomPaper>
      );
    }
  } else {
   
    // Ask a Query
    if (
      data?.getFeatureToPlatformPermission?.includes(
        PlatformPermissionFromFeatureTypes.CanQuery
      ) ||
      postPermissionsInAnyClub?.getPostPermissionsInAnyClub?.includes(
        PermissionsInAnyClub?.CanQuery
      )
    ) {
      menus.push(<AskAQueryButton />);
      uiButton.push(
        <CustomPaper variant="outlined">
          <Avatar
            src={me?.me?.avatar?.LowResUri || DEFAULT_AVATAR}
            alt="Avatar"
            hasRing={getIsRingVisible(me?.me?.membershipLevel)}
          />
          <Button fullWidth onClick={handleOpenQueryModal}>
            Ask a Query
          </Button>
        </CustomPaper>
      );
    }
    // Share a Stock Idea
    if (
      data?.getFeatureToPlatformPermission?.includes(
        PlatformPermissionFromFeatureTypes.CanCreateStockIdea
      ) ||
      postPermissionsInAnyClub?.getPostPermissionsInAnyClub?.includes(
        PermissionsInAnyClub?.CanCreateStockIdea
      )
    ) {
      menus.push(<ShareAStockIdeaButton />);
      uiButton.push(
        <CustomPaper variant="outlined">
          <Avatar
            src={me?.me?.avatar?.LowResUri || DEFAULT_AVATAR}
            alt="Avatar"
            hasRing={getIsRingVisible(me?.me?.membershipLevel)}
          />
          <Button fullWidth onClick={handleOpenStockIdeaModal}>
            Share a Stock Idea
          </Button>
        </CustomPaper>
      );
    }
     // Share an Insight
     if (
      data?.getFeatureToPlatformPermission?.includes(
        PlatformPermissionFromFeatureTypes.CanPost
      ) ||
      postPermissionsInAnyClub?.getPostPermissionsInAnyClub?.includes(
        PermissionsInAnyClub?.CanPost
      )
    ) {
      menus.push(<ShareAnInsightButton />);
      uiButton.push(
        <CustomPaper variant="outlined">
          <Avatar
            src={me?.me?.avatar?.LowResUri || DEFAULT_AVATAR}
            alt="Avatar"
            hasRing={getIsRingVisible(me?.me?.membershipLevel)}
          />
          <Button fullWidth onClick={handleOpenPostModal}>
            Start a Discussion
          </Button>
        </CustomPaper>
      );
    }
  }

  return (
    <React.Fragment>
      {menus?.length > 1 ? (
        <CustomPaper variant="outlined">
          <Avatar
            src={me?.me?.avatar?.LowResUri || DEFAULT_AVATAR}
            alt="Avatar"
            hasRing={getIsRingVisible(me?.me?.membershipLevel)}
          />
          <Button fullWidth onClick={handleOnClick}>
            Start a Discussion
          </Button>
        </CustomPaper>
      ) : (
        uiButton
      )}
      <Dialog open={open} onClose={handleCloseModal}>
        <DialogContent>
          <Stack flexDirection="column" gap={2}>
            {menus}
          </Stack>
        </DialogContent>
      </Dialog>
    </React.Fragment>
  );
};

const CustomPaper = styled(Paper)`
  padding: 20px 16px;
  margin-top: 16px;
  display: none;
  align-items: center;
  .MuiAvatar-root {
    margin-right: 8px;
  }
  .MuiButton-root {
    max-width: 100%;
    border-radius: 24px;
    justify-content: flex-start;
    padding-left: 16px;
    background-color: ${({ theme }: any) => theme.palette.action.selected};
    &:hover {
      background-color: ${({ theme }: any) => theme.palette.action.selected};
    }
  }
  @media (min-width: 960px) {
    display: flex;
  }
`;
