import { ApolloClient, createHttpLink, split, from } from "@apollo/client";
import { setContext } from "@apollo/client/link/context";
import { getMainDefinition } from "@apollo/client/utilities";
import { GraphQLWsLink } from "@apollo/client/link/subscriptions";
import { createClient } from "graphql-ws";
import { useMemo } from "react";
import { GRAPHQL_API_URL, GRAPHQL_SUBSCRIPTION_URL } from ".";
import { RestLink } from "apollo-link-rest";
import cache from "./cache";
import { getOIDCUser } from "config/oidc";

export const useClient = () => {
  return useMemo(() => {
    const httpLink = createHttpLink({
      uri: GRAPHQL_API_URL,
    });

    const restLink = new RestLink({
      uri: GRAPHQL_API_URL,
    });

    const authLink = setContext((_, { headers }) => {
      const user = getOIDCUser();
      const token = user?.access_token;
      return {
        headers: {
          ...headers,
          authorization: token ? `Bearer ${token}` : "",
        },
      };
    });
    
    const wsLink = new GraphQLWsLink(
      createClient({
        url: GRAPHQL_SUBSCRIPTION_URL,
        connectionParams: () => {
          const user = getOIDCUser();
          const accessToken = user?.access_token;
          if (accessToken)
            return {
              Authorization: `Bearer ${accessToken}`,
            };
          return {};
        },
      })
    );

    const splitLink = split(
      ({ query }) => {
        const definition = getMainDefinition(query);
        return (
          definition.kind === "OperationDefinition" &&
          definition.operation === "subscription"
        );
      },
      wsLink,
      from([authLink, restLink, httpLink])
    );

    return new ApolloClient({
      link: splitLink,
      cache,
    });
  }, []);
};
